<template>
  <div
    data-component-name="AppCheckbox"
    @click="$emit('update:modelValue', !modelValue)"
  >
    <input
      v-model="modelValue"
      type="checkbox"
      :id
      @click.self.stop
    >

    <div class="box">
      <SvgCheck />
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps<{ id?: string }>();
const modelValue = defineModel<boolean>();
</script>

<style scoped lang="scss">
@import "$/mixins/common";
@import "$/mixins/flex";
@import "$/mixins/typography";

@import "$/functions/token";

[data-component-name="AppCheckbox"] {
  cursor: pointer;
  display: inline-block;

  padding: 0.25rem;
  @include fixed-size(1.25rem);

  transition: background-color 0.15s ease;

  &:hover {
    background-color: rgba(token('accent-hover'), 16%);
  }

  &:active {
    background-color: rgba(token('accent-pressed'), 32%);
  }

  input[type="checkbox"] {
    display: none;

    &:checked ~ {
      .box {
        border-color: token('icon-action');
        background-color: token('icon-action');

        svg {
          opacity: 1;
        }
      }
    }
  }

  .box {
    position: relative;

    @include full-size;
    border: 1.5px solid token('icon-primary');

    transition: all 0.15s ease;

    svg {
      @include absolute-full;
      opacity: 0;
      transition: opacity 0.15s ease;
      transform: scale(2);
    }
  }
}
</style>
